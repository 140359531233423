<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader
                :contentType="'paymentInvoice'"
                :invoice="invoice"
                :invoiceNumber="invoice.number"
                :invoiceStatus="invoice.status"
                :id="state.invoiceReference"
                :invoiceForm="invoiceFormRef"
            />

            <template v-if="isLoading"><Spinner /></template>

            <div v-else ref="invoiceFormRef" class="w-90 center pv4-l pv3 mini-spacing">
                <div class="flex flex-wrap justify-between w-90 center">
                    <div class="w-75-l w-100 mb4 mb0-l pv4-l pv3 box-border" style="background: #FBFCFE;">
                        <div class="w-90 center pa3-l pa2 box-border" style="background: white;">
                            <div class="flex flex-wrap justify-between pv3">
                                <div class="font-w1 pb3 pb0-l">Invoice No {{ invoice.number }}</div>
                                <div class="flex">
                                    <div>Date issued: {{ formatDate(invoice.dateIssued) }}</div>
                                    <div class="pl3">Date due: {{ formatDate(invoice.expiryDate) }}</div>
                                </div>
                            </div>
                            <div class="pa3" style="background: #F7F9FD;">
                                <div class="flex items-center justify-between pb2">
                                    <div>
                                        <div class="f3 b pb2">Invoice</div>
                                        <div>{{ invoice.description }}</div>
                                    </div>
                                    <div class="db-l dn">
                                        <img src="imgs/BA-large.svg" alt="" />
                                    </div>
                                </div>
                                <div class="flex flex-wrap justify-between">
                                    <div class="pv3">
                                        <div class="b pb2" style="color: #132C8C;">Bill to</div>
                                        <div>
                                            <div class="pb2 b">{{ invoice.customer?.name }}</div>
                                            <div class="pb2">{{ invoice.billingAddress }}</div>
                                            <div class="pb2">{{ invoice.customer?.email }}</div>
                                            <div class="pb2">{{ invoice.customer?.phone }}</div>
                                        </div>
                                    </div>
                                    <div class="pv3 tr-l tl">
                                        <div class="b pb2" style="color: #132C8C;">Bill from</div>
                                        <div>
                                            <div class="pb2 b">{{ invoice.business?.name }}</div>
                                            <div class="pb2">{{ invoice.business?.address }}</div>
                                            <div class="pb2">{{ invoice.business?.email }}</div>
                                            <div class="pb2">{{ invoice.business?.phone }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pa3-l pa2">
                                <div class="flex justify-between pv3 box-border-bottom font-w1">
                                    <div class="w-30">Item</div>
                                    <div class="w-60 flex justify-between">
                                        <div class="w-30 tl">Cost</div>
                                        <div class="w-30 tc">Qty</div>
                                        <div class="w-30 tr">Price</div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="item of invoice.items" v-bind:key="item.id" class="pa3-l pa2">
                                <div class="flex justify-between pb3 box-border-bottom">
                                    <div class="w-30">{{ item.name }}</div>
                                    <div class="w-60 flex justify-between">
                                        <div class="w-30 tl">{{ item.price }}</div>
                                        <div class="w-30 tc">{{ item.quantity }}</div>
                                        <div class="w-30 tr">
                                            {{ formatAmount(item.price * item.quantity, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="ph3-l ph2">
                                <div class="flex justify-between pb5 font-w1">
                                    <div class="w-30-l"></div>
                                    <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                        <div class="w-30 tl">Total</div>
                                        <div class="w-30 tr b " style="color: #132C8C;">
                                            {{ formatAmount(invoice.totalAmount, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { formatAmount, formatQuantity, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'

import Spinner from '@/components/Spinner'

export default {
    name: 'PaymentInvoice',

    components: {
        AppWrapper,
        InvoiceHeader,
        Spinner
    },

    setup() {
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const { id } = route.params
        const transactionId = route.query.trxref

        const invoice = computed(() => store?.state.Invoice.paymentInvoice)
        const invoiceFormRef = ref(null)
        const isLoading = computed(() => store?.state.Invoice.isLoading)

        const state = reactive({
            invoiceReference: id ?? transactionId
        })

        onMounted(() => {
            store.dispatch('Invoice/getPaymentInvoice', state.invoiceReference.toString()).then(data => {
                if (data.isPaid) {
                    router.replace({ name: 'GetStarted' })
                }
            })
        })

        // watch invoice
        watch(invoice, newValue => {
            if (newValue) {
                //state.invoiceReference = newValue.reference
                // console.log(newValue, 'payment invoice');
            }
        })
        return {
            invoice,
            formatAmount,
            formatQuantity,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
            state,
            invoiceFormRef,
            isLoading
        }
    }
}
</script>

<style scoped></style>
